


import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";
import Axios from "axios";

import TreeView from "@/views/TreeView.vue";
import { ALLOWED_OCR_EXTENSIONS } from "@/constants";

@Component({ components: { TreeView } })
export default class ReviewOcrResponseComponent extends Vue {
  public allowedFileExtensions = ALLOWED_OCR_EXTENSIONS;
  public extractedData = null;
  public w22017 = [];
  public iRS2017 = {};
  public paychecks = [];
  public timingObj = null;
  public initialTime = null;
  public allStates = [
    { state: "Pennsylvania", code: "PA" },
    { state: "Florida", code: "FL" },
    { state: "Arizona", code: "AZ" },
    { state: "California", code: "CA" },
    { state: "Georgia", code: "GA" },
    { state: "Michigan", code: "MI" },
    { state: "Colorado", code: "CO" },
    { state: "Connecticut", code: "CT" }
  ];
  public selectedState: any = null;
  public selectedYear: any = null;
  public category: string = null;
  public loanTxnId: string = null;
  public requestProcessing = false;
  public fileName = null;

  public async getExtractedDocumentData(jobId = null) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/getExtractedDocumentData",
        {
          jobId: jobId
        });
      if (response.data.status == "complete") {
        this.requestProcessing = false;
        this.$store.state.wemloLoader = false;

        this.extractedData = response.data.data;
        this.timingObj = {
          TextDetectionDuration: this.calDuration(
            this.extractedData.finishStartTextDetectionOn,
            this.extractedData.requestCreatedOn
          ),
          ReadingTextDetectionDocDuration: this.calDuration(
            this.extractedData.finishGetTextDetectionOn,
            this.extractedData.finishStartTextDetectionOn
          ),
          TotalDuration: this.calDuration(
            this.extractedData.responseCreatedOn,
            this.extractedData.requestCreatedOn
          )
        };
        if (this.extractedData.totalPages > 0) {
          this.timingObj = {
            ...this.timingObj,
            ...{ TotalPages: this.extractedData.totalPages }
          };
        }

        if (
          this.extractedData.categoryName == "IRS Form W-2 2017" ||
          this.extractedData.categoryName == "IRS Form W-2 2018"
        ) {
          let year =
            this.extractedData.categoryName == "IRS Form W-2 2017"
              ? "2017"
              : "2018";
          this.w22017 = this.extractedData.extractedData[year];
        } else if (
          this.extractedData.categoryName ==
            "IRS Form 1040 (Individual Tax Return) 2017" ||
          this.extractedData.categoryName ==
            "IRS Form 1040 (Individual Tax Return) 2018"
        ) {
          let year =
            this.extractedData.categoryName ==
            "IRS Form 1040 (Individual Tax Return) 2017"
              ? "2017"
              : "2018";
          let irs1040 = this.extractedData.extractedData[year];
          let items = [];

          Object.keys(irs1040).forEach(key => {
            Object.keys(irs1040[key]).forEach(k => {
              this.iRS2017[k] =
                !irs1040[key][k] || irs1040[key][k] == ""
                  ? null
                  : irs1040[key][k];
            });
          });
        } else if (
          this.extractedData.categoryName == "2nd Most Recent Paystub"
        ) {
          this.paychecks = this.extractedData.extractedData["secondPaystub"];
        } else if (this.extractedData.categoryName == "Most Recent Paystub") {
          this.paychecks = this.extractedData.extractedData["firstPaystub"];
        }
      } else if (response.data.status == "error") {
        this.$store.state.wemloLoader = false;
        this.$snotify.error("Something went wrong! Please try after sometime.");
        this.clearAll();
      } else {
        if (this.checkInTime()) {
          setTimeout(() => {
            this.getExtractedDocumentData(jobId);
          }, 10000);
        } else {
          this.$snotify.error(
            "Something went wrong! Please try after sometime."
          );
          this.clearAll();
        }
      }
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error, "error");
      this.clearAll();
    }
  }
  public clearAll() {
    this.initialTime = null;
    this.requestProcessing = false;
    this.$store.state.wemloLoader = false;
    this.extractedData = null;
    this.w22017 = [];
    this.iRS2017 = {};
    this.paychecks = [];
    this.timingObj = null;
    this.fileName = null;
  }

  public calDuration = (dt2, dt1) => {
    var d1 = new Date(dt1);
    var d2 = new Date(dt2);
    var timeDiff = d2.getTime() - d1.getTime();
    var seconds = timeDiff / 1000;
    // get minutes
    var minutes = Math.floor(seconds / 60);
    // get seconds
    if (seconds > 60) {
      return minutes + " min";
    } else {
      if (seconds < 0) {
        return Math.abs(seconds * 1000) + " ms";
      } else return seconds + " sec";
    }
  };

  /**
   * To get initial data like list of states and others whatever will be required.
   */
  private async getOcrReviewInitialData() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/getOcrReviewInitialData",
        {});

      this.allStates = response.data.states;
    } catch (error) {
      console.log(error);
    }
  }

  public async saveFileAndStartOcrProcessing() {
    try {
      let formValid = await this.$validator.validateAll();
      if (!formValid) {
        return;
      }
      this.requestProcessing = true;
      this.$store.state.wemloLoader = true;
      this.initialTime = new Date().getTime();
      let categoryName =
        this.category != "Driver License(s)"
          ? this.category + " " + this.selectedYear
          : this.category;

      let categoryType =
        this.category == "Driver License(s)"
          ? "Identification"
          : "Income Documents";

      let formData = new FormData();
      let filesData: any = this.$refs.abc;

      let bodyObj = {
        state: this.selectedState
      };

      Object.keys(filesData.files).forEach(f => {
        formData.append("file", filesData.files[f]);
      });

      formData.append("loanTxnId", this.loanTxnId);
      formData.append("categoryName", categoryName);
      formData.append("categoryType", categoryType);
      formData.append("body", JSON.stringify(bodyObj));
      
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/saveFileAndStartOcrProcessing",
        formData,
        {
          timeout: 1000*1000
        }
      );
      this.requestProcessing = false;
      
      // this.$store.state.wemloLoader = false;
      if (response.data.jobId && this.checkInTime()) {
        setTimeout(() => {
          this.getExtractedDocumentData(response.data.jobId);
        }, 40000);
      }
    } catch (error) {
      this.requestProcessing = false;
      this.$store.state.wemloLoader = false;
      console.log(error);
    }
  }

  checkInTime() {
    let nowTime = new Date().getTime();
    let timeDiff = nowTime - this.initialTime;
    let sec = Math.floor(timeDiff / 1000);
    return sec >= 900 ? false : true;
  }
  mounted() {
    this.loanTxnId = "456dvd5vfd54v";
    // this.getExtractedDocumentData(
    //   "451138fdd187fc34c8b7030846a43cfe12d1b6dceb245beddf397cb084618acb"
    // );
  }

  calculateDataType(data) {
    let dataTypes = ["string", "array", "object"];
    if (Array.isArray(data)) return dataTypes[1];
    if (typeof data === "string") return dataTypes[0];
    return dataTypes[2];
  }
}
