






import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class TreeViewComponent extends Vue {
  @Prop() treeViewData: any;
  public htmlContent = "";

  generateTree(treeViewData: any) {
    if (!treeViewData) return "";

    function recurse(data: any) {
      var htmlRetStr = "<ul>";
      for (let key in data) {
        if (typeof data[key] == "object" && data[key] != null) {
          var x: any = Number(key) * 1;
          if (isNaN(x)) {
            htmlRetStr += "<li>" + key + ":<ul>";
          }
          htmlRetStr += recurse(data[key]);
          htmlRetStr += "</ul></li>";
        } else {
          htmlRetStr += "<li>" + key + ": &quot;" + data[key] + "&quot;</li  >";
        }
      }
      htmlRetStr += "</ul >";
      return htmlRetStr;
    }
    var htmlStr = recurse(treeViewData);

    return htmlStr;
  }

  isPlainObject(obj) {
    return Object.prototype.toString.call(obj) === "[object Object]";
  }

  mounted() {
    this.htmlContent = this.generateTree(this.treeViewData);
  }
}
